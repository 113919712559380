import React,{useState,useEffect} from "react";
import "./PhotoBlockModal.css";
import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import {AiFillCheckCircle, AiOutlineClose} from 'react-icons/ai';
import { useNavigate } from "react-router-dom";


function PhotoBlockModal(props) {

  const navigate = useNavigate();

  const [errorMessage,setErrorMessage] = useState();
  const [confirm,setConfirm] = useState(false);
  const [creditsNumber,setCreditsNumber] = useState();
  const [close, setClose] = useState();
  const { t, i18n, ready } = useTranslation();


  // useEffect(() => {
  //   props.confirm(confirm);
  // });


  function isNumber(str) {
    if (str.trim() === '') {
      return false;
    }
  
    return !isNaN(str);
  }

  useEffect(() => {
    // props.close(close);
    document.body.classList.add('no-overflow');
    if(close){
      document.body.classList.remove('no-overflow');
    }
  });


  function postSendCredits(e){
    e.preventDefault();

    let is_error = false;
    setErrorMessage("");
    if(creditsNumber){
      if(!isNumber(creditsNumber)){
      setErrorMessage(t("Please enter a number!"));
      is_error = true;
      }
    }
    else{
      is_error = true;
      setErrorMessage(t("This field can't be empty!"));
    }

    props.confirm(creditsNumber)

  }


  return (
    <div className="modal-confirmation">
      <div className="modal-content-credits">
        <>
        <div className="modal-header text-center">
          <p>{t("Type how many credits you want to set for this photo ") + "  "} <span className="bld"></span></p>
        </div>
        <div>
        </div>
        <form
            style={{ textAlign: "center", marginTop: "20px" }}
            onSubmit={(e) => postSendCredits(e)}
          >
            <div className="form-login-item">
              <input
                className={"w-100"}
                type="text"
                value={creditsNumber}
                onChange={(e) => {
                  setCreditsNumber(e.target.value);
                }}
                placeholder={t("Number of credits")}
              />
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <button className="main-btn tb-color howver p-color w-40" onClick={(e) => postSendCredits(e)}>{t("Set photo credits")}</button>
          </form>
          </>
          
      </div>
    </div>
  );
}

export default PhotoBlockModal;
