import React, { useState, useEffect, useContext } from "react";
import "./Profile.css";
import UserDetails from "../Components/User/UserDetails";
import profileGirl from "../images/myphotos/profilegirl.png";
import Modal from "../Components/Modal/Modal";
import {
  FaGrinWink,
  FaVenusMars,
  FaRegBuilding,
  FaBirthdayCake,
  FaCircle,
} from "react-icons/fa";
import { toast } from 'react-toastify';
import { AiOutlineSearch } from "react-icons/ai";
import { BsGlobe } from "react-icons/bs";
import { AiFillHeart, AiFillLike } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { FiDelete } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";
import Account from "../Components/Profile/Profile";
import { UserContext } from "../App";
import config from "../config";
import axios from "axios";

import {useTranslation} from 'react-i18next';
import ImageUpload from "../Components/ImageUpload/ImageUpload";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";

function ProfileEdit() {
  const [user, setUser] = useState();
  const [modalValue, setModalValue] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const [idDeletedPhoto, setIdDeletedPhoto] = useState();
  const [idChangePhoto, setIdChangePhoto] = useState();
  const [modalText, setModalText] = useState();
  const { t, i18n, ready } = useTranslation();
  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    const response = await axios.get(config.url + "users/id/me", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    setUser(response.data.user);
  };


  useEffect(() => {
    setStateModal();
  },[idDeletedPhoto]);

  const setStateModal = async (childrenData,value) => {

    if (childrenData === true || childrenData === false) {
      setModalValue(childrenData);
      setDisplayModal(false);
    }

    if (childrenData === true) {
      if (typeModal === "delete") {
        await axios.delete(config.url + "users/picture/id/" + idDeletedPhoto, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        toast(t("Your photo has been deleted:") + idDeletedPhoto);
      } else if (typeModal === "change_photo") {
        await axios.put(
          config.url + "users/picture/main/id/" + idChangePhoto,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        toast(t("Your profile photo has been updated"));
      }

      window.location.reload();
    }
  };

  const deletePhoto = async (e) => {

    setDisplayModal(true);
    setModalText(t("Are you sure you want to delete this photo?"));
    setTypeModal("delete");
    setModalValue(e.target.id.substr(3));
    setIdDeletedPhoto(e.target.id.substr(3));
  };

  const setMainPhoto = async (e) => {
    setDisplayModal(true);
    setModalText(t("Are you sure you want to set this photo as main?"));
    setTypeModal("change_photo");
    setModalValue(true);
    setIdChangePhoto(e.target.id.substr(5));
  };

  const Photo = ({ id, photo }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <div
        className="member-details-img img-background pos-rel"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && (
          <TiDelete
            className="icon-delete tb-color pos-abs"
            id={"del" + photo.id}
            onClick={deletePhoto}
          />
        )}
        {isHovered && photo.is_main != 1 && (
          <div className="div-set-profile pos-abs w-100">
            <button
              className="main-btn howver  tb-color  f-white"
              id={"photo" + photo.id}
              onClick={setMainPhoto}
            >
              Set as profile
            </button>
          </div>
        )}
        <img src={photo.src} alt="profile-member-img" />
      </div>
    );
  };

  return (
    <section className="profile-section">
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3 left-side">
            <Account user={user} />
          </div>
          <div className="col-md-9 mb-3 left-side">
            <UserDetails user={user} isForm={true} />

            <div className="user-details member-img-all">
              <h3>{t("Photos")}</h3>

              {user?.pictures &&
                user.pictures.length > 0 &&
                user.pictures.map((photo, index) => {
                  return <Photo key={index} id={index} photo={photo} />;
                })}

              <ImageUpload />
            </div>
          </div>
        </div>
        <div className="left-side"></div>
      </div>

      {displayModal && modalValue && <Modal text={modalText} confirm={setStateModal} />}
    </section>
  );
}

export default ProfileEdit;
