import React from 'react';
import './Login.css';
import LoginForm from '../Components/Forms/LoginForm/LoginForm';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import config from '../config'
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb';
import {useTranslation} from 'react-i18next';

function Login() {
  const { t, i18n, ready } = useTranslation();
  return (
      <>
        {/* <Breadcrumb pageTitle={t(process.env.REACT_APP_APP_NAME + " Login Page")} text={t("What are you waiting for? Login into your account start the conversation")}/> */}
        <LoginForm/>
     </>
  )
}

export default Login