import React,{useState,useEffect} from "react";
import "./Modal.css";
import {useTranslation} from "react-i18next";
import { parsePath } from "react-router-dom";

function Modal(props) {

  const [confirm,setConfirm] = useState();
  const { t, i18n, ready } = useTranslation();
  useEffect(() => {
    props.confirm(confirm);
  });

  return (
    <div className="modal-confirmation">
      <div className="modal-content">
        <div className="modal-header text-center">
          <p>{props.text}</p>

        </div>
        <div>
        <p className="text-center">{props?.subtitle}</p>
        {props.credits && <div className="text-center"> <p> <strong>{props.credits > 1 ? props.credits + ' credits' : t('free')}</strong></p></div>}

        </div>
      
        <div className="modal-body ">
          <button className="main-btn tb-color p-color w-40 howver" onClick={() => setConfirm(true)}>{t("Yes")}</button>
          <button className="main-btn tb-color p-color w-40 howver" onClick={() => setConfirm(false)}>{t("No")}</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
