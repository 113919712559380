
import "./ConfigNew";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Pages/Home";
import Profile from "./Pages/Profile";
import ProfileUser from "./Pages/ProfileUser";
import ProfileEdit from "./Pages/ProfileEdit";
import ErrorPage from "./Pages/ErrorPage";
import Header from "./Components/Header/Header";
import Login from "./Pages/Login";
import SignUp from "./Pages/Signup";
import Footer from "./Pages/Footer";
import Members from "./Pages/Members";
import Messages from "./Pages/Messages";
import Membership from "./Pages/Membership";
import Checkout from "./Pages/Checkout/Checkout";
import ProcessPayment from "./Pages/ProcessPayment/ProcessPayment";
import Thankyou from "./Pages/Thankyou/Thankyou";
import ModalAge from "./Components/ModalAge/ModalAge";
import ActivateAccount from "./Pages/ActivateAccount/ActivateAccount";
import ProtectedRoutes from "./ProtectedRoutes";
import Contact from "./Pages/Contact/Contact";
import PublicRoutes from "./PublicRoutes";
import { createContext, useState, Suspense, useEffect } from "react";
import axios from "axios";
import config from "./config";
import UserGuide from "./Pages/StaticPages/UserGuide";
import PrivacyPolicy from "./Pages/StaticPages/PrivacyPolicy";
import Terms from "./Pages/StaticPages/Terms";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Vonage from "./Pages/Vonage/Vonage";
import VonageCall from "./Pages/Vonage/VonageCall";
import Chat from "./Components/Chat/Chat";
export const UserContext = createContext();

function App() {
    const token = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : false;

const [user, setUser] = useState({ loggedIn: token });

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            return await axios.get(config.url + "users/id/me", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
        };

        checkUserLoggedIn().catch((error) => {
            if (error.response.status === 401) {
                setUser({ loggedIn: false });
                localStorage.removeItem("token");
            }
        });
    }, [token]);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <Suspense fallback={<p>Loading...</p>}></Suspense>
            <Router>
            
                    <Header />
              
                <div className="content">
                    <Routes>
                        <Route element={<ProtectedRoutes />}>
                            <Route path="/my-profile" element={<Profile />} />
                            <Route path="/members" element={<Members />} />
                            <Route
                                path="/profile/:id"
                                element={<ProfileUser />}
                            />
                            <Route
                                path="/profile-edit"
                                element={<ProfileEdit />}
                            />
                            <Route
                                path="/membership-credits"
                                element={<Membership />}
                            />
                            <Route
                                path="/messages/:receiver_id"
                                element={<Messages />}
                            />
                            <Route path="/messages/" element={<Messages />} />
                            <Route
                                path="/checkout/:product_type/:product_id"
                                element={<Checkout />}
                            />
                            <Route
                                path="/process-payment"
                                element={<ProcessPayment />}
                            />
                            <Route
                                path="/videocall"
                                element={<Chat />}
                            />
                            <Route path="/thank-you" element={<Thankyou />} />
                        </Route>

                        <Route element={<PublicRoutes />}>
                            <Route path="/login" element={<Login />} />
                            <Route path="/signup" element={<SignUp />} />
                            <Route path='/forgot-password' element={<ForgotPassword/>}/>
                            <Route path="/" element={<Home />} />
                        </Route>
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/user-guide" element={<UserGuide />} />
                        <Route
                            path="/privacy-policy"
                            element={<PrivacyPolicy />}
                        />
                        <Route
                            path="/terms-and-conditions"
                            element={<Terms />}
                        />
                        <Route
                            path="/activate-account/:token"
                            element={<ActivateAccount />}
                        />
                        <Route
                            path="/password-reset/:token"
                            element={<ResetPassword />}
                        />
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </div>
                <Footer />
                <ModalAge />
            </Router>

            <ToastContainer
                position="bottom-right"
                autoClose="5000"
                toastClassName="toastify-notification"
                progressClassName="toastify-progressbar"
            />
        </UserContext.Provider>
    );
}

export default App;
