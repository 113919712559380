import React from "react";
import HomeForm from "../Components/Forms/HomeForm/HomeForm";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import { useTranslation } from "react-i18next";
import homePhoto from "../images/luvbinder/home.jpg";

function Home() {
    const navigate = useNavigate();
    const { t, i18n, ready } = useTranslation();
    return (
        <>
            <div className="container bb-color home-container">
                <div className="row">
                    <div className="col-md-6 d-grid justify-content-center align-items-center home-left">
                        <div>
                            {" "}
                            <h3>
                                Introducing {process.env.REACT_APP_APP_NAME}
                            </h3>
                            <p>
                                Serious Dating With{" "}
                                <strong>
                                    {process.env.REACT_APP_APP_NAME}{" "}
                                </strong>{" "}
                                Your Perfect Match is Just a Click Away.
                            </p>
                        </div>
                        <div>
                            <button className="form-button tb-color f-white howver" onClick={() => navigate('/signup')} >
                                {t("Find your partner")}
                            </button>

                            
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="home-photo">
                            <img src={homePhoto} className=""></img>
                        </div>
                    </div>
                </div>

                {/* <HomeForm /> */}
            </div>
        </>
    );
}

export default Home;
