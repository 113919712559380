import React, { useState, useEffect } from "react";
import "./Membership.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { BsCheckLg } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import config from "../config";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";

function Membership() {
    const [pricingPlans, setPricingPlans] = useState([]);
    const [benefits, setBenefits] = useState([]);
    const { t, i18n, ready } = useTranslation();

    useEffect(() => {
        getPricingPlans();
    }, []);

    const getPricingPlans = async () => {
        if (localStorage.getItem("token")) {
            const response = await axios.get(config.url + "products", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log(response.data);
            setPricingPlans(response.data);
            if (response.data.memberships) {
                setBenefits(response.data.memberships[0].benefits);
            }
        }
    };

    return (
        <>

            {/* <Breadcrumb pageTitle={t("Memberships and credits")} text = {t("Unleash the power of our platform by purchasing credits and memberships, enabling you to send virtual gifts, initiate private chats")}/> */}
            <div className="container" style={{overflow: 'hidden'}}>
                <div className="container membership-table">
                    <div className="each-membership-new">
                        <div className="header-benefit">
                            <h5
                                className="bld-900"
                                style={{ marginTop: "20px" }}
                            >
                                Membership
                            </h5>
                        </div>
                        <div className="each-membership-new">
                            {benefits.map((benefit) => {
                                return <span>{benefit.name}</span>;
                            })}
                            <div className="each-membership-new">
                                <span>{t("Price")}</span>
                            </div>
                        </div>
                    </div>
                    {pricingPlans?.memberships &&
                        pricingPlans.memberships.map((membership) => (
                            <div className="each-membership-new">
                                <div className="header-benefit">
                                    <h5 className="bld-900">
                                        {membership.name}
                                    </h5>
                                    <span>
                                        {membership.available_for_days +
                                            " " +
                                            t("days")}
                                    </span>
                                </div>

                                {membership.benefits.map((benefit) => {
                                    if (benefit.value > 0)
                                        return (
                                            <span
                                                key={
                                                    "benefit" +
                                                    membership.id +
                                                    benefit.id
                                                }
                                            >
                                                {benefit.value_text ? (
                                                    benefit.value_text
                                                ) : (
                                                    <BsCheckLg />
                                                )}
                                            </span>
                                        );
                                    else if (benefit.value == 0)
                                        return (
                                            <span>
                                                <AiOutlineClose />
                                            </span>
                                        );
                                    else
                                        return (
                                            <span>{benefit.value_text}</span>
                                        );
                                })}

                                <span
                                    style={{
                                        height: "20px",
                                        textDecoration: "line-through",
                                    }}
                                >
                                    {membership.old_price}€
                                    <span className="prom-off">{membership.promotion_percentage}% OFF</span>
                                </span>
                                <span className="t-color bld card-price">
                                    <span>       {membership.price}€</span>
                             
                                </span>
                                {/* {membership.price}€ */}

                                <div className="mebership-button w-100">
                                    <Link
                                        to={
                                            "/checkout/memberships/" +
                                            membership.id
                                        }
                                        className="main-btn tb-color p-color howver pad-25 membership-button"
                                    >
                                        Buy now
                                    </Link>
                                </div>
                            </div>
                        ))}
                </div>
                <div className="bb-color mt-4 membership-v2">
                <h3>{t("Credit Packages")}</h3>
                <div className="membership-container">
                    {pricingPlans?.credits &&
                        pricingPlans.credits.map((credit) => (
                            <div className="each-membership">
                                <div className="pricing-image">
                                    <img src={credit.picture} />
                                </div>

                                <h5 className="bld-900">{credit.name}</h5>
                                <span className="t-color bld card-price">
                                    {credit.price}€
                                </span>
                                <span>{credit.credits} credits</span>

                                {credit.bonus ? (
                                    <span>
                                        {" + " +
                                            credit.bonus +
                                            " credits BONUS"}
                                    </span>
                                ) : null}

                                <div className="mebership-button w-100">
                                    <Link
                                        to={"/checkout/credits/" + credit.id}
                                        className="main-btn tb-color p-color howver pad-25 membership-button"
                                    >
                                        Buy now
                                    </Link>
                                </div>
                            </div>
                        ))}
                </div>
                </div>
                <div className="bb-color mt-4 membership-v2">
                <h3>{t("Combo Packages")}</h3>
                <div className="membership-container mb-4">
                    {pricingPlans?.combo_credits &&
                        pricingPlans.combo_credits.map((credit) => (
                            <div className="each-membership">
                                <div className="pricing-image">
                                    <img src={credit.picture} />
                                </div>

                                <h5 className="bld-900">{credit.name}</h5>

                                <span className="t-color bld card-price">
                                    {credit.price}€
                                </span>

                                <span>{credit.membership} </span>

                                <span>
                                    {credit.credits &&
                                        " + " + credit.credits + " credits"}
                                </span>
                                <div className="mebership-button w-100">
                                    <Link
                                        to={
                                            "/checkout/combo_credits/" +
                                            credit.id
                                        }
                                        className="main-btn tb-color p-color howver pad-25 membership-button"
                                    >
                                        Buy now
                                    </Link>
                                </div>
                            </div>
                        ))}
                </div>
                </div>
            </div>
        </>
    );
}

export default Membership;
