import getPartialSdpAnswer from './partialSdpTransformations/getPartialSdpAnswer';
import SDPHelpersDefault from './sdp_helpers';
import getPartialIceRestartSdpOffer from './partialSdpTransformations/getPartialIceRestartSdpOffer';
import createGetFullRemoteSdp from './partialSdpTransformations/getFullRemoteSdp';

const createPeerConnectionSDP = (deps = {}) => {
  const sdpHelpers = deps.helpers || SDPHelpersDefault;
  const getFullRemoteSdp = deps.getFullRemoteSdp || createGetFullRemoteSdp();
  const peerConnectionSDP = {
    sdp: {
      mids: [],
      mediaSections: [],
      headers: [],
      bundle: null,
      version: null,
      tracks: [],
    },
    subscribePcIdsByMid: {},

    updateParsedSdp(sdp) {
      Object.assign(this.sdp, sdp);
    },

    updateSdpWithNewOffer(sdp) {
      const parsedSdp = sdpHelpers.updateSDPWithNewOffer({
        mediaSections: this.sdp.mediaSections,
        headers: this.sdp.headers,
        version: this.sdp.version,
      }, sdp.sdp);
      Object.assign(this.sdp, parsedSdp);
    },

    setOfferAndCreateAnswer(offer) {
      this.updateSdpWithNewOffer(offer);
      return sdpHelpers.createSDP(this.sdp.headers, this.sdp.mediaSections);
    },

    addSubscriberMid(mid, subscriberPcId) {
      this.subscribePcIdsByMid[mid] = subscriberPcId;
    },

    getPartialSdpAnswer(newAnswer) {
      if (!this.midsToAdd) {
        return newAnswer;
      }
      const partialSdp = getPartialSdpAnswer(sdpHelpers.parseSDP(newAnswer), this.midsToAdd);
      this.midsToAdd = undefined;
      return sdpHelpers.createSDP(partialSdp.headers, partialSdp.mediaSections);
    },

    getIceRestartPartialSdp(newOffer) {
      const partialSdp = getPartialIceRestartSdpOffer(sdpHelpers.parseSDP(newOffer));
      return sdpHelpers.createSDP(partialSdp.headers, partialSdp.mediaSections);
    },

    getPartialSdp(type, fullSdp) {
      if (type === 'answer') {
        return this.getPartialSdpAnswer(fullSdp);
      }
      return this.getIceRestartPartialSdp(fullSdp);
    },

    processRemoteSdp(type, sdp) {
      const parsedRemoteSdp = sdpHelpers.parseMantisSDP(sdp);
      if (type === 'offer') {
        this.midsToAdd = parsedRemoteSdp.mids;
      }
      const fullSdp = getFullRemoteSdp(type, parsedRemoteSdp, this.sdp);
      this.updateParsedSdp(fullSdp);
      return sdpHelpers.createSDP(fullSdp.headers, fullSdp.mediaSections);
    },

    removeSubscriberMids(subscriberPcId) {
      const midsToRemove = Object.keys(this.subscribePcIdsByMid)
        .filter(mid => this.subscribePcIdsByMid[mid] === subscriberPcId);

      midsToRemove.forEach((mid) => {
        this.sdp.mids[this.sdp.mids.findIndex(m => m === mid)] = null;
        delete this.subscribePcIdsByMid[mid];
      });
    },

    isHead(subscriberPcId) {
      const headMid = this.sdp.mids.find(mid => mid !== null);
      return this.subscribePcIdsByMid[headMid] === subscriberPcId;
    },
  };

  return peerConnectionSDP;
};

export default createPeerConnectionSDP;
