import React, {useEffect, useState, useContext, useRef} from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo_centralpay from "../images/logo/logo_centralpay.png";
import { UserContext } from "../App";
import { MdCallEnd } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import Vonage from '../Pages/Vonage/Vonage';
import { toast } from "react-toastify";
import config from "../config";
import axios from 'axios'

export default function Footer() {
    const { t } = useTranslation();
    const delay = 10;
    const apiKey = 47849401;

    const [isCalling, setIsCalling] = useState(false);
    const [isCallAccepted, setIsCallAccepted] = useState(false);
    const [isCallEnd, setIsCallEnd] = useState(false);
    const [show, setShow] = useState(false);
    const [confirm,setConfirm] = useState(false);
    const { user, setUser } = useContext(UserContext);
    const [userReceiver, setUserReceiver] = useState(false);
    const [calls, setCalls] = useState();

    const audioRef = useRef(new Audio('/ringtone.mp3'));

    const handleTogglePlay = (isPlaying) => {

        if (!isPlaying) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        } else {
        audioRef.current.play().catch((error) => console.error('Error playing audio:', error));
        }

    };
    
    const getProfileReceiverDetails = async (userId) => {
        if (userId === undefined) return;
        const response = await axios.get(
            config.url + "users/id/" + userId,
            {
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        setUserReceiver(response.data.user);
    };


    useEffect(() => {
        const isActiveCall = localStorage.getItem("activeCall");
        // console.log(isActiveCall);
        // console.log(isActiveCall === "false");
        // (isActiveCall == "false" || isActiveCall == undefined)
        if(user.loggedIn && (isActiveCall == "false" || isActiveCall == undefined)){
            let timer1 = setTimeout(() => {
                setShow(!show);
                waitCalls();
            }, delay * 1000);
    
            return () => {
                clearTimeout(timer1);
            };
        }
    
    }, [show]);


    const waitCalls = async () => {
        
        const response = await axios.get(config.url + "chat/vonage/calls",{
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        // console.log(response.data)
        if(response.data?.calls.length > 0){
            getProfileReceiverDetails(response.data.calls[0]?.subscriber_id)
            setIsCalling(true);
            setCalls(response.data.calls[0])
            handleTogglePlay(true);
            // console.log(response.data.calls[0]);
        }
        else{
            localStorage.setItem("activeCall", false);
        }
      };

      const endSession = async (sessionId) => {
        const response = await axios.post(config.url + "chat/vonage/end", { session_id: sessionId },{
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          setIsCallEnd(true);
          setIsCalling(false);
          setIsCallAccepted(false);
          setCalls([]);

          localStorage.setItem("activeCall", false);
        //   console.log("End call");
        //   console.log(response);

          toast("The call has ended!")
          setTimeout(() => {
            window.location.reload();
        }, 2000);
      };


      const joinSession = async (sessionId) => {
        const response = await axios.post(config.url + "chat/vonage/join", { session_id: sessionId },{
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
  
   
      };

      const acceptCall = async () => {
        const response = await axios.get(config.url + "chat/vonage/calls",{
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          if(response.data?.calls.length > 0){
            handleTogglePlay(false);
            setIsCallAccepted(true);
            setIsCalling(false);
            joinSession(calls?.session_id);
            localStorage.setItem("activeCall", true);
          }
        else{
            handleTogglePlay(false);
            setIsCalling(false);
            toast("The user has closed the video call.")
        }
      }

      const rejectCall = () => {
        setIsCalling(false);
        handleTogglePlay(false);
        endSession(calls?.session_id);
        toast("The call request was declined on your end")
      }


    return (
        <footer>
            <div className="container">
                <div className="text-center mg-top-10">
                    <ul className="footer-links">
                        <li className="mg-left-10">
                            <Link className="link-white-light" to="/contact">
                                {t("Contact")}
                            </Link> 
                        </li>
                        <li className="mg-left-10 ">
                            <Link className="link-white-light" to="/user-guide">
                                {t("User guide")}
                            </Link>
                        </li>
                        <li className="mg-left-10">
                            <Link
                                className="link-white-light"
                                to="/privacy-policy"
                            >
                                {t("Privacy policy")}
                            </Link>
                        </li>
                        <li className="mg-left-10">
                            <Link
                                className="link-white-light"
                                to="/terms-and-conditions"
                            >
                                {t("Terms and conditions")}
                            </Link>
                        </li>
                    </ul>
                    <div>
                        <p> 
                            &copy; 2024{" "}
                            <a className="link-bold link-white-button" href="/">
                                {process.env.REACT_APP_APP_NAME}
                            </a>{" "}
                            {t(" - Don't miss your chance")}
                        </p> 
                    </div>
                </div>
                <div className="d-flex-center mg-top-10">
                    <a href="https://customer.centralpay.net/home/eb48411d-3b49-4ded-aeff-b7594813795b">
                        <img src={logo_centralpay} alt="logo_central_pay" />
                    </a>
                </div>
            </div>
        { isCalling && 
            <div className="modal-confirmation">
                <div className="modal-content">
                    <div className="modal-header text-center">
                   
                    <div className="animated mg-left-20">
                      <div className="profile-details">
              <img
                src={userReceiver?.main_pic}
                className="profile-photo img-background"
                alt="account-img"
              />
            </div>
                 <p>{userReceiver?.username} is calling you</p>

            </div>
                    </div>
                    <div>

                    </div>
                
                    <div className="modal-body ">
            
                    <button className="button-accept main-btn b-color tp-color w-40 " onClick={() => acceptCall()}><IoMdCall /></button>
                    <button className="main-btn tb-color p-color w-40 " onClick={() => rejectCall()}><MdCallEnd /> </button>
                    </div>
                </div>
            </div>
        }
        {calls && isCallAccepted && 
         <div className="modal-confirmation">
            <div className="modal-video">
                <Vonage user={{"id": calls?.publisher_id}} onCallEnded={endSession} apiKey={apiKey} sessionId={calls?.session_id} publisherToken={calls?.publisher_token} />
            </div>
        </div>
        }

        </footer>
    );
}
