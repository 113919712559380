import React, { useState, useContext, useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useNavigate,
    Navigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import { BsFillChatFill } from "react-icons/bs";
import { IoMdNotifications } from "react-icons/io";
import Notification from "../Notification/Notification";
import { FaUser, FaUsers } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import "./Header.css";


import axios from "axios";
import config from "../../config";
import { UserContext } from "../../App";
import { initReactI18next, useTranslation, Trans } from "react-i18next";
import ModalProm from "../ModalProm/ModalProm";


let logo;
import(`../../images/${process.env.REACT_APP_WEBSITE_ID}/logo.png`).then((module) => {
  logo = module.default;
});

function Header() {
    const delay = 30;
    const [show, setShow] = useState(false);
    const [displayNotifications, setdisplayNotifications] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [newMessages, setNewMessages] = useState();
    const [newNotifications, setNewNotifications] = useState();
    const [notifications, setNotifications] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [stateChat, setStateChat] = useState(false);

    useEffect(() => {
        getMessages();
        getNewNotifications();
        getChatSettings();
    }, []);
    useEffect(() => {
        let timer1 = setTimeout(() => {
            setShow(!show);
            getMessages();
            getNewNotifications();
        }, delay * 1000);

        return () => {
            clearTimeout(timer1);
        };
    }, [show]);

    useEffect(() => {
        if (displayNotifications) {
            getNotifications();
        }
    }, [displayNotifications]);

    const getMessages = async () => {
        if (localStorage.getItem("token")) {
            const response = await axios.get(config.url + "messages/new", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });

            setNewMessages(response.data.new_messages_number);
        }
    };
    const getChatSettings = async () => {
        try {
            const response = await axios.get(config.url + "chat/settings", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
        } catch (err) {
            if (err.response.status == 403) {
                console.log(err.response.status);
                setStateChat(true);
            }
        }
    };

    const getNewNotifications = async () => {
        if (localStorage.getItem("token")) {
            const response = await axios.get(config.url + "notifications/new", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });

            setNewNotifications(response.data.new_messages_number);
        }
    };

    const getNotifications = async () => {
        const response = await axios.get(config.url + "notifications", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        setNotifications(response.data.notifications);
    };

    const { t, i18n, ready } = useTranslation();

    const [isActive, setActive] = useState(false);
    const { user, setUser } = useContext(UserContext);

    const [currentLanguage, setCurrentLanguage] = useState(
        localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
    );
    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location.reload();
    };

    const handleLanguage = (e) => {
        const newLanguage = e.target.getAttribute("data-id");
        setCurrentLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
        localStorage.removeItem("lang");
        localStorage.setItem("lang", newLanguage);
        toast(t("You changed your preffered language"));
        axios
            .post(
                config.url + "users/language",
                {
                    lang: newLanguage,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
            .then((result) => {
                if (result.status === 200) {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getLanguages = async () => {
        const response = await axios.get(config.url + "translate/languages", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        if (response.data) {
            setLanguages(response.data);

            let newLanguage = await getCountryByIp();

            if (newLanguage) {
                const is_valid_country = response.data.find(
                    ({ code }) => code === newLanguage
                );
                console.log(is_valid_country);
                newLanguage = is_valid_country ? newLanguage : "en";
                setCurrentLanguage(newLanguage);
                i18n.changeLanguage(newLanguage);
                localStorage.removeItem("lang");
                localStorage.setItem("lang", newLanguage);
            }
        }
    };
    useEffect(() => {
        getLanguages();
    }, []);

    const getCountryByIp = async () => {
        const currentLanguageIP = localStorage.getItem("lang")
            ? localStorage.getItem("lang")
            : false;
        let country_id = false;
        if (currentLanguageIP == false) {
            const response = await axios.get("https://ipapi.co/json/");
            country_id = response.data.country_code.toLowerCase();
        }
        return country_id;
    };

    //   useEffect(() => {
    //     const currentLanguageIP = localStorage.getItem("lang") ? localStorage.getItem("lang") : false;
    //     if(currentLanguageIP == false){
    //       axios.get('https://ipapi.co/json/')
    //         .then(response => {
    //         const newLanguage = response.data.country_code.toLowerCase();

    //         console.log(languages);
    //         // setCurrentLanguage(newLanguage);
    //         // i18n.changeLanguage(newLanguage);
    //         // localStorage.removeItem("lang");
    //         // localStorage.setItem("lang", newLanguage);
    //     }).catch((data, status) => {
    //       //  console.log('Request failed:', data);
    //     });
    //   }
    //  },[])

    const toggleClass = () => {
        setActive(!isActive);
    };

    return (
        <header>
            <div>
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo">
                            <a href="/">
                               {logo && <img src={logo} alt="logo" />}
                            </a>
                        </div>
                        <div
                            className={
                                isActive ? "menu-area active" : "menu-area"
                            }
                        >
                            <ul className="menu-links">
                                {user.loggedIn ? (
                                    <>
                                        <li>
                                            <Link
                                                to="/members"
                                                className="link-white-darker"
                                                onClick={() => setActive(false)}
                                            >
                                                {t("Members")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/my-profile"
                                                className="link-white-darker"
                                                onClick={() => setActive(false)}
                                       
                                            >
                                                {t("Profile")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/profile-edit"
                                                className="link-white-darker"
                                                onClick={() => setActive(false)}
                                            >
                                                {t("Edit")}
                                            </Link>
                                        </li>
                                        <li>
                                        <Link
                                                    to="/messages"
                                                    className={
                                                        "link-white-darker pos-rel"
                                                    }
                                                    onClick={() =>
                                                        setActive(false)
                                                    }
                                                >
                                                   {t("Messages")}
                                                    <span className="badge-new">
                                                        {" "}
                                                        {newMessages
                                                            ? newMessages
                                                            : ""}
                                                    </span>
                                                </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/membership-credits"
                                                className="link-white-darker"
                                                onClick={() => setActive(false)}
                                            >
                                                {t("Price plans")}
                                                <div className="prom-off">
                                                    40% OFF
                                                </div>
                                            </Link>
                                        </li>
                                     
                                        <div className="icons-header no-mobile">
                                        
                                              
                                        
                                            <li
                                                onClick={() =>
                                                    setdisplayNotifications(
                                                        !displayNotifications
                                                    )
                                                }
                                                className="notification-new"
                                            >
                                                <span>
                                                    <IoMdNotifications
                                                        style={{
                                                            fontSize: "20px",
                                                        }}
                                                    />
                                                    {/* {t("Notifications")} */}
                                                </span>
                                                <span className="badge-new">
                                                    {" "}
                                                    {newNotifications
                                                        ? newNotifications
                                                        : ""}
                                                </span>
                                                {displayNotifications && (
                                                    <div
                                                        className={
                                                            "notification-content" +
                                                            (!notifications
                                                                ? " no-notif"
                                                                : "")
                                                        }
                                                    >
                                                        {notifications &&
                                                            notifications.map(
                                                                (
                                                                    notification
                                                                ) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                "notif" +
                                                                                notification.id
                                                                            }
                                                                            className="each-notification"
                                                                        >
                                                                            <div>
                                                                                {
                                                                                    notification.text
                                                                                }
                                                                            </div>{" "}
                                                                            <div className="notification-time">
                                                                                {" "}
                                                                                {
                                                                                    notification.created_at
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        {!notifications && (
                                                            <p
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    marginTop:
                                                                        "16px",
                                                                }}
                                                            >
                                                                {t(
                                                                    "You don't have any notifications yet!"
                                                                )}{" "}
                                                            </p>
                                                        )}
                                                    </div>
                                                )}
                                            </li>
                                            {/* <div className="notification">
                        <li
                          className={
                            "icon-link icon-notification" +
                            (newNotifications ? " active-icon" : "")
                          }
                          onClick={() =>
                            setdisplayNotifications(!displayNotifications)
                          }
                        >
                          <IoMdNotifications />{" "}
                          <span>
                            {newNotifications ? newNotifications : ""}
                          </span>
                        </li>
                        {displayNotifications && (
                          <div className={"notification-content" + (!notifications ? " no-notif" : "") }>
                            
                            {notifications &&
                          notifications.map((notification) => {
                            return (
                              <div key={"notif" + notification.id} className="each-notification">
                                <div>{notification.text}</div>{" "}
                                <div className="notification-time"> {notification.created_at}</div>
                              </div>
                            );
                          })}
                            {!notifications && <p style={{textAlign: "center", marginTop:"16px"}}>{t("You don't have any notifications yet!")} </p>}
                          </div>
                        )}
                      </div> */}
                                        </div>
                                    </>
                                ) : (
                                    <li>
                                        <Link
                                            to="/"
                                            className="link-white-darker"
                                            onClick={() => setActive(false)}
                                        >
                                            {t("Home")}
                                        </Link>
                                    </li>
                                )}

                                <li className="dropdown-lng no-mobile">
                                    {languages &&
                                        languages.map((country) => {
                                            if (country.code == currentLanguage)
                                                return (
                                                    <img
                                                        key={
                                                            "langphoto" +
                                                            country.code
                                                        }
                                                        className="img-lng"
                                                        src={country.img}
                                                    ></img>
                                                );
                                        })}
                                    <div className="dropdown-lng-content">
                                        {languages &&
                                            languages.map((country) => {
                                                if (
                                                    country.code !=
                                                    currentLanguage
                                                )
                                                    return (
                                                        <div
                                                            key={
                                                                "countrylang" +
                                                                country.code
                                                            }
                                                            className="item-lng"
                                                        >
                                                            <img
                                                                data-id={
                                                                    country.code
                                                                }
                                                                onClick={
                                                                    handleLanguage
                                                                }
                                                                className="img-lng"
                                                                src={
                                                                    country.img
                                                                }
                                                            ></img>
                                                        </div>
                                                    );
                                            })}
                                    </div>
                                </li>
                            </ul>
                            <div className="menu-btn">
                                {user.loggedIn ? (
                                    <>

                                        <button
                                            onClick={handleLogout}
                                            className="main-btn main-btn-header pb-color t-color logout-button"
                                        >
                                            {" "}
                                            <i className="icofont-user"></i>{" "}
                                            <span>
                                                {" "}
                                                <MdLogout className="icon-center-header" />{" "}
                                                {t("LOG OUT")}
                                            </span>
                                        </button>

                                        
                                     
                                    </>
                                ) : (
                                    <>
                                        <Link
                                            className="login pb-color t-color hover-scale "
                                            to="/login"
                                            onClick={() => setActive(false)}
                                        >
                                            {" "}
                                            <i className="icofont-user"></i>{" "}
                                            <span>
                                                {" "}
                                                <FaUser className="icon-center-header" />{" "}
                                                {t("LOG IN")}
                                            </span>
                                        </Link>
                                        <Link
                                            className="signup tb-color hover-scale "
                                            to="/signup"
                                            onClick={() => setActive(false)}
                                        >
                                            {" "}
                                            <i className="icofont-users"></i>{" "}
                                            <span>
                                                {" "}
                                                <FaUsers className="icon-center-header" />{" "}
                                                {t("SIGN UP")}
                                            </span>
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="mobile-menu-area">
                            {user.loggedIn && (
                                <>
                                    <Link
                                        to="/messages"
                                        className={"notification-new"}
                                        onClick={() => setActive(false)}
                                    >
                                        <BsFillChatFill /> 
                                        <span className="badge-new">
                                            {" "}
                                            {newMessages ? newMessages : ""}
                                        </span>
                                    </Link>{" "}
                                    <li
                                        onClick={() =>
                                            setdisplayNotifications(
                                                !displayNotifications
                                            )
                                        }
                                        className="notification-new"
                                    >
                                        <span>
                                            <IoMdNotifications 
                                                style={{ fontSize: "20px" }}
                                            />
                                            
                                        </span>
                                        <span className="badge-new">
                                            {" "}
                                            {newNotifications
                                                ? newNotifications
                                                : ""}
                                        </span>
                                        {displayNotifications && (
                                            <div
                                                className={
                                                    "notification-content" +
                                                    (!notifications
                                                        ? " no-notif"
                                                        : "")
                                                }
                                            >
                                                {notifications &&
                                                    notifications.map(
                                                        (notification) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        "notif" +
                                                                        notification.id
                                                                    }
                                                                    className="each-notification"
                                                                >
                                                                    <div>
                                                                        {
                                                                            notification.text
                                                                        }
                                                                    </div>{" "}
                                                                    <div className="notification-time">
                                                                        {" "}
                                                                        {
                                                                            notification.created_at
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                {!notifications && (
                                                    <p
                                                        style={{
                                                            textAlign: "center",
                                                            marginTop: "16px",
                                                        }}
                                                    >
                                                        {t(
                                                            "You don't have any notifications yet!"
                                                        )}{" "}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </li>
                                </>
                            )}
                            <div className="dropdown-lng">
                                {languages &&
                                    languages.map((country) => {
                                        if (country.code == currentLanguage)
                                            return (
                                                <img
                                                    key={
                                                        "langphotomob" +
                                                        country.code
                                                    }
                                                    className="img-lng"
                                                    src={country.img}
                                                ></img>
                                            );
                                    })}
                                <div className="dropdown-lng-content">
                                    {languages &&
                                        languages.map((country) => {
                                            if (
                                                country.code !== currentLanguage
                                            )
                                                return (
                                                    <div
                                                        key={
                                                            "countrylangmob" +
                                                            country.code
                                                        }
                                                        className="item-lng"
                                                    >
                                                        <img
                                                            data-id={
                                                                country.code
                                                            }
                                                            onClick={
                                                                handleLanguage
                                                            }
                                                            className="img-lng"
                                                            src={country.img}
                                                        ></img>
                                                    </div>
                                                );
                                        })}
                                </div>
                            </div>
                            <IoMdMenu
                                className="burger"
                                onClick={toggleClass}
                            />
                        </div>
                        {stateChat && (
                            <div
                                id="inactive-chat"
                                onClick={() => {
                                    toast(
                                        t(
                                            "If you want to use chat, please upgrade membership!"
                                        )
                                    );
                                    setTimeout(() => {
                                        window.location.href =
                                            "/membership-credits";
                                    }, 3500);
                                }}
                            >
                                <span>Chat</span>
                            </div>
                        )}
                    </div>
                </div>
                {user.loggedIn && <ModalProm />}
            </div>
        </header>
    );
}

export default Header;
