import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./LoginForm.css";
import config from "../../../config";
import homePhoto from "../../../images/luvbinder/home.jpg";
function Login() {
    const navigate = useNavigate();

    const [password, setPassword] = useState();
    const [username, setUsername] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [isPassword, setIsPassword] = useState(false);
    const [isUsername, setIsUsername] = useState(false);
    const { t, i18n, ready } = useTranslation();
    function postLogin(e) {
        e.preventDefault();
        setIsUsername(false);
        setIsPassword(false);

        if (username === undefined || username === "") {
            setIsUsername("Username is required");
        }
        if (password === undefined || password === "") {
            setIsPassword("Password is required");
        }

        if (username && password) {
            axios
                .post(config.url + "login", {
                    username: username,
                    password: password,
                })
                .then((result) => {
                    if (result.status === 200) {
                        const token = result.data.token.access_token;
                        if (token) {
                            localStorage.setItem("token", token);
                            if (result.data.token.first_login_date) {
                                localStorage.setItem(
                                    "first_login_date",
                                    result.data.token.first_login_date
                                );
                            }
                            localStorage.setItem(
                                "is_admin",
                                result.data.token?.is_admin
                            );
                            window.location.reload();
                        }
                    }
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        setErrorMessage(
                            "There is no account with this username and password"
                        );
                    } else {
                        setErrorMessage(error.response.data.error);
                    }
                });
        }
    }
    return (
        <div className="container bb-color home-container big-form">
            <div className="row justify-content-center">
              <div className="col-md-6 photo-content">
                    <div className="home-photo">
                        <img src={homePhoto} className=""></img>
                    </div>
                </div>
                <div className="col-md-6 d-grid justify-content-center align-items-center home-left ">
                    <div>
                        <div className="login-form-new bb-color">
                            <h3>
                                <strong>{t("Login")}</strong>
                            </h3>
                            <form
                                style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                }}
                                onSubmit={(e) => postLogin(e)}
                            >
                                <div className="form-login-item">
                                    <input
                                        className={
                                            "w-100" +
                                            (isUsername ? " red-error" : "")
                                        }
                                        type="text"
                                        value={username}
                                        onChange={(e) => {
                                            setUsername(e.target.value);
                                        }}
                                        placeholder={t("Username")}
                                    />
                                </div>
                                {isUsername && (
                                    <div className="error-message">
                                        {isUsername}
                                    </div>
                                )}
                                <div className="form-login-item">
                                    <input
                                        className={
                                            "w-100" +
                                            (isPassword ? " red-error" : "")
                                        }
                                        type="password"
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        placeholder={t("Password")}
                                    />
                                </div>
                                {isPassword && (
                                    <div className="error-message">
                                        {isPassword}
                                    </div>
                                )}
                                <div className="d-flex justify-content-end flex-wrap pt-sm-2 extra-group">
                                    <a
                                        className="link-white-light"
                                        onClick={() =>
                                            navigate("/forgot-password")
                                        }
                                    >
                                        {t("Forget Password?")}
                                    </a>
                                </div>
                                {errorMessage && (
                                    <div className="error-message">
                                        {errorMessage}
                                    </div>
                                )}
                                <button className="form-button tb-color f-white howver">
                                    {t("Submit now")}
                                </button>
                                <div className="login-extra-row">
                                    <p>
                                        {t("Don't have an account?")}{" "}
                                        <a
                                            className="link-white-light"
                                            onClick={() => navigate("/signup")}
                                        >
                                            {t("Sign up")}
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        // <div className="container">
        //   <div className="login-form bb-color">
        //     <h3>
        //       <strong>{t("Login")}</strong>
        //     </h3>
        //     <form
        //       style={{ textAlign: "center", marginTop: "20px" }}
        //       onSubmit={(e) => postLogin(e)}
        //     >
        //       <div className="form-login-item">
        //         <input
        //           className={"w-100" + (isUsername ? " red-error" : "")}
        //           type="text"
        //           value={username}
        //           onChange={(e) => {
        //             setUsername(e.target.value);
        //           }}
        //           placeholder={t("Username")}
        //         />
        //       </div>
        //       {isUsername && <div className="error-message">{isUsername}</div>}
        //       <div className="form-login-item">
        //         <input
        //           className={"w-100" + (isPassword ? " red-error" : "")}
        //           type="password"
        //           value={password}
        //           onChange={(e) => {
        //             setPassword(e.target.value);
        //           }}
        //           placeholder={t("Password")}
        //         />
        //       </div>
        //       {isPassword && <div className="error-message">{isPassword}</div>}
        //       <div className="d-flex justify-content-end flex-wrap pt-sm-2 extra-group">
        //         <a className="link-white-light" onClick={() => navigate('/forgot-password')}>
        //         {t("Forget Password?")}
        //         </a>
        //       </div>
        //       {errorMessage && (
        //         <div className="error-message">{errorMessage}</div>
        //       )}
        //       <button className="form-button tb-color f-white howver">
        //       {t("Submit now")}
        //       </button>
        //       <div className="login-extra-row">
        //         <p>
        //           {t("Don't have an account?")} <a className="link-white-light" onClick={() => navigate('/signup')} >{t("Sign up")}</a>
        //         </p>
        //       </div>
        //     </form>
        //   </div>
        // </div>
    );
}

export default Login;
